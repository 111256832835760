import React, { useContext, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './defaultHeader.css';
import MenuDesktop from '../shared/menu-desktop';
import { TemplatesContext } from '../../template-context';
import MenuMobile from '../shared/menu-mobile';
import SocialLinks from '../shared/social-links';
import { useTheme } from '../../templates/common/themes';
import { useContent } from './useContent';
import { useStyle } from './useStyle';

export const DefaultHeader = () => {
	const { website } = useContext(TemplatesContext);
	const header = useContent();
	const styles = useStyle();
	const mainHeaderTheme = useTheme({ colorIndex: styles.themeColorIndex });
	const tapToCallTheme = useTheme({ colorIndex: styles.tapToCallColorIndex });

	return (
		<header>
			{header.phone && (
				<a href={`tel: ${header.phone}`} className="d-block d-md-none tap-to-call" style={tapToCallTheme.containerStyles}>
					{header.tapToCallTitle ? header.tapToCallTitle : 'Tap to call'}
				</a>
			)}

			<div style={{ position: 'relative' }}>
				<MenuMobile />
				<div style={{ display: 'flex', flexDirection: styles.menuShowOnTop ? 'column-reverse' : 'column' }}>
					<Container fluid style={mainHeaderTheme.containerStyles}>
						<Row className="main-section">
							<Col xs="9" md="auto">
								{header && header.imagePath ? (
									<img
										className="logo"
										src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${header.imagePath}`}
										alt={header.imageAltText}
									/>
								) : (
									header && header.companyName && <h3>{header.companyName}</h3>
								)}
							</Col>
							<Col className="d-none d-md-flex right-section">
								{header && (
									<div className="inner-wrapper">
										<div className="details">
											{header.phone && (
												<div className="call-us">
													Call us on: <a href={`tel: ${header.phone}`}>{header.phone}</a>
												</div>
											)}
											{header.email && (
												<div className="email">
													email: <a href={`mailto: ${header.email}`}>{header.email}</a>
												</div>
											)}
										</div>
										<SocialLinks
											socialLinkInstagram={header.socialLinkInstagram}
											socialLinkFacebook={header.socialLinkFacebook}
											socialLinkLinkedIn={header.socialLinkLinkedIn}
											socialLinkTwitter={header.socialLinkTwitter}
											socialLinkPinterest={header.socialLinkPinterest}
											socialLinkYoutube={header.socialLinkYoutube}
											themeColorIndex={styles.socialLinksColorIndex}
										/>
									</div>
								)}
							</Col>
						</Row>
					</Container>

					<MenuDesktop />
				</div>
			</div>
		</header>
	);
};
